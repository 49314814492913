import request from '@/utils/request'
// pots  params query 传值 data body传值   data: JSON.stringify(data)
// 获取APP的首页导航数据 
export function getAppNavigtion(params) {
  return request({
    url: `/v2/api/app/ap_contribution/app/AppContribution/getAppNavigtion`,
    method: 'post',
    params
  })
}
// 获取栏目下的文章列表
export function getColumnContribution(params) {
  return request({
    url: `/v2/api/app/ap_contribution/app/AppContribution/getColumnContribution`,
    method: 'post',
    params
  })
}
// 动态获取指定文章的详细信息
export function getContribution(params, path) {
  return request({
    url: path,
    method: 'post',
    params
  })
}
// 统计当前区域的参加的活动数和团队数

export function Homestaticdata(params) {
  return request({
    url: `/v2/api/app/ap_member/member/query/center/static_data`,
    method: 'get',
    params
  })
}
// 招募活动
export function Homeactivitylist(params) {
  return request({
    url: `/v2/api/app/ad_activity/activity/query_recruit_page`,
    method: 'get',
    params
  })
}
// 获取指定文章的详细信息
export function newsDratil(params) {
  return request({
    url: `/v2/api/app/ap_contribution/app/AppContribution/getContribution`,
    method: 'post',
    params
  })
}
// 获取指定栏目的id
export function getColumnChildlist(params) {
  return request({
    url: `/v2/api/app/ap_contribution/app/AppContribution/getColumnChildlist`,
    method: 'post',
    params
  })
}
// 动态获取带导航的文章
export function getColumnWithNav(params, path) {
  return request({
    url: path,
    method: 'post',
    params
  })
}
//
export function searchNew(params, data) {
  return request({
    url: `/v2/api/app/ap_contribution/app/AppContribution/getAPPContributionByPage?appName=${params}`,
    method: 'post',
    data: JSON.stringify(data)
  })
}

export default { getAppNavigtion, getColumnContribution, getContribution, getColumnWithNav, Homestaticdata, Homeactivitylist, newsDratil, getColumnChildlist, searchNew }