import request from '@/utils/request'

// 点赞及取消点赞
export function addOrMovePriase(params) {
    return request({
        url: `/v2/api/app/ap_contribution//app/AppContribution/praiseHandle`,
        method: 'post',
        params
    })
}

// 查询是否点赞
export function isPraise(params) {
    return request({
        url: `/v2/api/app/ap_contribution//app/AppContribution/getPraiseContribution`,
        method: 'post',
        params
    })
}

// 查询是否收藏文章
export function isCollect(params) {
    return request({
        url: `/v2/api/app/ap_contribution//app/AppContribution/contributionIsCollection`,
        method: 'post',
        params
    })
}

// 收藏文章及取消收藏
export function addOrMoveCollection(params) {
    return request({
        url: `/v2/api/app/ap_contribution//app/AppContribution/contributionCollection`,
        method: 'post',
        params
    })
}

// 查询文章阅读数
export function getArticleNum(params) {
    return request({
        url: `/v2/api/app/ap_contribution//app/AppContribution/getContributionReadNum`,
        method: 'post',
        params
    })
}

// 增加文章阅读数
export function addArticleNum(params) {
    return request({
        url: `/v2/api/app/ap_contribution//app/AppContribution/addContributionReadNum`,
        method: 'post',
        params
    })
}

// 查询评论
export function getCommentList(data,params) {
    return request({
        url: `/v2/api/app/ap_contribution//app/contributionComment/getContributionCommentPage`,
        method: 'post',
        data: data,
        params: params
    })
}

// 发表评论
export function addComment(data,params) {
    return request({
        url: `/v2/api/app/ap_contribution//app/contributionComment/releaseComment`,
        method: 'post',
        data: data,
        params: params
    })
}

export default { addOrMovePriase, addOrMoveCollection, getArticleNum, addArticleNum, isPraise, isCollect, getCommentList, addComment }