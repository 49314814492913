<template>
    <div class="loading" v-show="iscomplete" id="loadContent">
        <span class="iconfont icon-jiazaizhong1" :style="loadstyle"></span>
    </div>
</template>

<script>
export default {
    props: {
		iscomplete: {  //是否显示
			type: Boolean,
			default:()=>false, //必须是一个函数
			required: true,   
        },
        loadstyle:{ //icon样式
            type: Object,
            default:()=>{}, //必须是一个函数
			required: false,  //不是必填项
        }
    }
}
</script>

<style  scoped>
    .loading{
        background: #fff;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .loading span{
        color: #E32416;
        animation: myfirst 0.5s linear infinite;
    }

    @keyframes myfirst
    {
        from {
           transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }
</style>
